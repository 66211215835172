<template>
  <div>
    <TheNavbarHorizontal></TheNavbarHorizontal>
    <div>
      <div>
        <vs-row vs-justify="center">
          <vs-row
            type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="12"
            vs-sm="4"
            vs-xs="12"
          >
            <div class="back-grewond2">
              <vs-row
                vs-justify="flex-center"
                vs-lg="12"
                vs-sm="4"
                vs-xs="12"
                style="padding: 10%"
              >
                <vs-row vs-lg="12" vs-sm="4" vs-xs="12">
                  <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="4"
                    vs-sm="4"
                    vs-xs="12"
                  >
                  </vs-col>
                  <vs-col
                    style="padding: 2%"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="4"
                    vs-sm="4"
                    vs-xs="12"
                  >
                    <img src="../../assets/images/doclnia.png" />
                    <!-- <img src="" alt=""> -->
                  </vs-col>
                </vs-row>
                <vs-row
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="12"
                  vs-md="12"
                  vs-sm="12"
                  vs-xs="12"
                >
                  <h1 style="color: aliceblue">patient waiver</h1>
                </vs-row>
              </vs-row>
            </div>
          </vs-row>
        </vs-row>
      </div>
    </div>
    <br />
    <vs-row vs-lg="4" vs-md="12" vs-sm="12">
      <vs-col
        vs-lg="3"
        vs-md="6"
        vs-sm="4"
        vs-justify="center"
        vs-align="center"
        style="text-align: center"
        ><h1 style="color: #132953">Introduction</h1></vs-col
      >
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          The terms and conditions for this website contained in this webpage,
          property of Cure Journey Portal, a civil company, with license nº
          966764 and address in: Souk Al Bahar, Saha offices, office 202, Dubai,
          UAE, are meant to govern the use of the website, and every page within
          the website (the collective term used for all the web pages is
          Website). The terms are enforceable and influence the manner in which
          the website is used. By accepting to use the website, you particularly
          accept all the detailed terms and conditions outlined here. This
          website must not be used if you protest any one of the terms and
          conditions outlined herein. This website cannot be used by minors
          (minors, in this case, mean people who have not attained the age of 18
          years). Anyone who is a minor is prohibited from accessing the
          website. What are website’ standard terms and conditions? The terms
          and conditions for this website have been articulated in the form of a
          list of rules created with an intention of setting a basis under which
          the business relationship can be established and for the purpose of
          regulating the website’s repetitive actions.
        </h4>
      </vs-col>
    </vs-row>
    <br />

    <br />

    <!--  -->
    <br />
    <vs-row vs-lg="4" vs-md="12" vs-sm="12">
      <vs-col
        vs-lg="4"
        vs-md="6"
        vs-sm="12"
        vs-justify="center"
        vs-align="center"
        style="text-align: end ;"
        ><h1 style="color: #132953 ;    padding-right: 16%;">Intellectual Property Rights</h1></vs-col
      >
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          Except for the content which you have voluntarily chosen to include in
          the website Medical Tourism Business under the stipulated rules, the
          licensor has full ownership of all the materials and intellectual
          property contained in the website, and every right has been reserved.
          All users have only been given limited license for the content
          dependent on the terms restrictions, for viewing material inside the
          website. Panda Tip: If and where the content in the website has been
          designed for viewership, then the user can pick it up from the last
          point above. Having said that, you will probably be required to offer
          more with respect to descriptive language concerning what the user is
          allowed to use our website for.
        </h4>
      </vs-col>
    </vs-row>
    <br />

    <br />

    <!--  -->
    <br />
    <vs-row vs-lg="4" vs-md="12" vs-sm="12">
      <vs-col
        vs-lg="3"
        vs-md="6"
        vs-sm="9"
        vs-justify="center"
        vs-align="center"
        style="text-align: center"
        ><h1 style="color: #132953">Restrictions</h1></vs-col
      >
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          Website users are categorically and particularly delimited from:
          Publication of any of the materials in this website on any media
          Commercializing, sublicensing, or selling any material from this
          website . Utilizing the website in such a way that might damage it
          Showing and/or performing any of the website material in the public.
          Use of the website in such a way that influences access to the website
          by other users. Using the website in a manner that contravenes
          regulations and applicable laws, or a manner that might harm or
          inflict harm on the website or any business or person. Use of the
          website for the purpose of engaging in marketing or advertisement.
          Performing any data harvesting, data mining, data extraction or such
          like activities on the website or during the use of this website.
          Registration on this website is prohibited for users less than 18
          years old. Some of the areas in this website Medical Tourism Business
          have been restricted from users and further restrictions may be
          imposed on accessing other website areas at the discretion of the
          website owner. Any password and user identification owned for
          accessing this website is confidential and such information must be
          held in confidence.
        </h4>
      </vs-col>
    </vs-row>

    <!--  -->
    <br />
    <vs-row vs-lg="4" vs-md="12" vs-sm="12">
      <vs-col
        vs-lg="3"
        vs-md="12"
        vs-sm="12"
        vs-justify="center"
        vs-align="center"
        style="text-align: center"
        ><h1 style="color: #132953">Your Content</h1></vs-col
      >
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          In accordance with the terms and conditions of this website, the
          content for the user means any video, audio, images, text, or any
          other materials the users opt to display from the website. With regard
          to the content displayed by users, the user grants the owner a
          worldwide, non-exclusive, royalty-free, irrevocable, sub-licensable
          license for the use, publishing, adaptation, translation,
          reproduction, and distribution of such content to any media. The
          content displayed by users has to be their own and should never
          trespass the rights of any third parties. Medical Tourism Business
          holds the rights to delete any user content from the website at their
          discretion without giving notice, and for their own reasons.
        </h4>
      </vs-col>
    </vs-row>
    <br />

    <!--  -->

    <!--  -->
    <br />
    <vs-row vs-lg="4" vs-md="12" vs-sm="12">
      <vs-col
        vs-lg="3"
        vs-md="8"
        vs-sm="6"
        vs-justify="center"
        vs-align="center"
        style="text-align: center"
        ><h1 style="color: #132953">No warranties</h1></vs-col
      >
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          The website has been provided in its current form, with all the faults
          and there is no implied or express warranty representation from
          Medical Tourism Business in any form in relation to the website or
          contained materials. In addition, no information contained on the
          website should be taken to mean that advice or consultancy is provided
          to the user.
        </h4>
      </vs-col>
    </vs-row>
    <br />
    <vs-row vs-lg="4" vs-md="12" vs-sm="12">
      <vs-col
        vs-lg="3"
        vs-md="8"
        vs-sm="12"
        vs-justify="center"
        vs-align="center"
        style="text-align: center"
        ><h1 style="color: #132953">Limitation of liability</h1></vs-col
      >
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          Under no circumstances shall the website Medical Tourism Business or
          any of the employees, directors and officers be held liable to the
          website user for any situation emanating from the use of the website
          or from the legal contracts sampled in any way, whether or not such a
          liability falls under tort, the contract or any other situation.
          Medical Tourism Business as well as the directors, employees, and
          officers will not be held accountable for their indirect, special or
          consequential liability emanating from anything associated with the
          website’s use.’s use.
        </h4>
      </vs-col>
    </vs-row>
    <br />

    <vs-row vs-lg="4" vs-md="12" vs-sm="12">
      <vs-col
        vs-lg="3"
        vs-md="8"
        vs-sm="8"
        vs-justify="center"
        vs-align="center"
        style="text-align: center"
        ><h1 style="color: #132953">Indemnification</h1></vs-col
      >
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          The user fully insures Medical Tourism Business against and from any
          liabilities, causes, demands, damages, expenses, and costs emanating
          from or in relation to any violation of the terms provided (including
          the attorney fees deemed reasonable).
        </h4>
      </vs-col>
    </vs-row>
    <br />
    <vs-row vs-lg="4" vs-md="12" vs-sm="12">
      <vs-col
        vs-lg="3"
        vs-md="8"
        vs-sm="8"
        vs-justify="center"
        vs-align="center"
        style="text-align: center"
        ><h1 style="color: #132953">Severability</h1></vs-col
      >
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          In case any provision in the terms is deemed invalid or unenforceable
          under any of the applicable laws, then such invalidity or
          unenforceability will not cause the terms invalid or unenforceable in
          totality. In addition, the invalid and unenforceable provision will be
          removed without influencing the other provisions in any way.
        </h4>
      </vs-col>
    </vs-row>
    <br />

    <!--  -->
    <br />
    <vs-row vs-lg="4" vs-md="12" vs-sm="12">
      <vs-col
        vs-lg="4"
        vs-md="8"
        vs-sm="8"
        vs-justify="center"
        vs-align="center"
        style="text-align: center"
        ><h1 style="color: #132953">Variation of Terms</h1></vs-col
      >
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          The website Medical Tourism Business holders are allowed to introduce
          revisions to the terms as they wish and as they deem fit. By accepting
          to use the website there is an expectation that you will read the
          terms regularly in order to make sure that all the terms and
          conditions guiding the website’s use are understood.
        </h4>
      </vs-col>
    </vs-row>
    <br />
    <!--  -->
    <br />
    <vs-row vs-lg="4" vs-md="12" vs-sm="12">
      <vs-col
        vs-lg="3"
        vs-md="8"
        vs-sm="8"
        vs-justify="center"
        vs-align="center"
        style="text-align: center"
        ><h1 style="color: #132953">Assignment</h1></vs-col
      >
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          The website Medical Tourism Business has permission to transfer,
          subcontract, and assign its obligations and rights under the
          stipulated terms without having to get consent or to issue a
          notification. The website users are, however, not allowed to transfer,
          subcontract, or assign any of the obligations and/or rights under the
          terms.
        </h4>
      </vs-col>
    </vs-row>
    <br />
    <!--  -->
    <br />
    <vs-row vs-lg="4" vs-md="12" vs-sm="12">
      <vs-col
        vs-lg="4"
        vs-md="8"
        vs-sm="8"
        vs-justify="center"
        vs-align="center"
        style="text-align: center"
        ><h1 style="color: #132953">Entire Agreement</h1></vs-col
      >
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          All the terms, with the inclusion of disclaimers and legal notices in
          the website, make up the wholesome contract between the user and or
          Medical Tourism Business with regard to the website’s use. The terms
          are superior to any prior understandings and agreements with regard to
          the website.
        </h4>
      </vs-col>
    </vs-row>
    <br />
    <!--  -->
    <br />
    <vs-row vs-lg="4" vs-md="12" vs-sm="12">
      <vs-col
        vs-lg="5"
        vs-md="8"
        vs-sm="8"
        vs-justify="center"
        vs-align="center"
        style="text-align: center ;   "
        ><h1 style="color: #132953">Payment terms and conditions</h1></vs-col
      >
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
          We accept payments online using VISA & MASTER CARD credit/debit cards
          in USD The cardholder must retain a copy of the transaction records
          and “Cure Journey Portal” policies and rules. User is responsible for
          maintaining the confidentiality of his account. All credit/debit
          cards’ details and personally identifiable information will NOT be
          stored, sold, shared, rented or leased to any third parties. If you
          make a payment for our products or services on our website, the
          details you are asked to submit will be provided directly to our
          payment provider via a secured connection. “Cure Journey Portal” will
          not pass any debit/credit card details to third parties. “Cure Journey
          Portal” takes appropriate steps to ensure data privacy and security
          including through various hardware and software methodologies.
          However, “www.doclinia.com” cannot guarantee the security of any
          information that is disclosed online. “Cure Journey Portal” is not
          responsible for the privacy policies of websites to which it links. If
          you provide any information to such third parties different rules
          regarding the collection and use of your personal information may
          apply. You should contact these entities directly if you have any
          questions about their use of the information that they collect. Some
          of the advertisements you see on the Site are selected and delivered
          by third parties, such as ad networks, advertising agencies,
          advertisers, and audience segment providers. These third parties may
          collect information about you and your online activities, either on
          the Site or on other websites, through cookies, web beacons, and other
          technologies in an effort to understand your interests and deliver to
          you advertisements that are tailored to your interests. Please
          remember that we do not have access to, or control over, the
          information these third parties may collect. The information practices
          of these third parties are not covered by this privacy policy.
        </h4>
      </vs-col>
    </vs-row>
    <br />
    <!--  -->
    <br />
    <vs-row vs-lg="4" vs-md="12" vs-sm="12">
      <vs-col
        vs-lg="4"
        vs-md="8"
        vs-sm="8"
        vs-justify="center"
        vs-align="center"
        style="text-align: center"
        ><h1 style="color: #132953">Cancelation conditions</h1></vs-col
      >
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
            The cancellation conditions are clearly illustrated on each service provided before the step of booking. The user is obliged to read carefully the cancellation policies and conditions.
            By proceeding with the booking and payment of the service, the user integrally agrees on the cancellation conditions and policies.
        </h4>
      </vs-col>
    </vs-row>
    <br />
    <!--  -->
    <br />
    <vs-row vs-lg="4" vs-md="12" vs-sm="12">
      <vs-col
        vs-lg="4"
        vs-md="8"
        vs-sm="8"
        vs-justify="center"
        vs-align="center"
        style="text-align: center"
        ><h1 style="color: #132953">Refund Policy
        </h1></vs-col
      >
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
            Refund will be made onto the original mode of payment and will be processed within 10 to 45 days depends on the issuing bank of the credit card
Deductions may arise from the refunded amount based on operational and paper works.
           </h4>
      </vs-col>
    </vs-row>
    <br />
    <!--  -->
   
    <!--  -->
    <br />
    <vs-row vs-lg="4" vs-md="12" vs-sm="12">
      <vs-col
        vs-lg="4"
        vs-md="8"
        vs-sm="8"
        vs-justify="center"
        vs-align="center"
        style="text-align: center"
        ><h1 style="color: #132953">Service delivery

        </h1></vs-col
      >
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
            Doclinia will NOT deal or provide any services or products to any of OFAC (Office of Foreign Assets Control) sanctions countries in accordance with the law of UAE.
           </h4>
      </vs-col>
    </vs-row>
    <br />
    <!--  -->
    <br />
    <vs-row vs-lg="4" vs-md="12" vs-sm="12">
      <vs-col
        vs-lg="4"
        vs-md="8"
        vs-sm="8"
        vs-justify="center"
        vs-align="center"
        style="text-align: center ;    padding-left: 6%;"
        ><h1 style="color: #132953">Governing law and jurisdiction

        </h1></vs-col
      >
    </vs-row>
    <br />
    <vs-row vs-lg="12" vs-md="12" vs-sm="12" style="padding-left: 9%">
      <vs-col vs-lg="9" vs-md="12" vs-sm="12">
        <h4 style="color: #000">
            The terms for this website are construed and governed in conformity with UAE Laws. The website users must accept to be submitted to courts of Dubai and nonexclusive jurisdiction of UAE for the purpose of dispute resolution. 

UAE is the domicile country of Cure Journey Portal, a civil company in Dubai, with address in Souk Al Bahar, Saha offices, office 202, Dubai, UAE, and stipulates that the governing law is the local law

The outlines do not form any attorney-client arrangement between any entities or persons. Furthermore, the outline is available for all members under an agreement that the company isn’t concerned with providing any professional or legal advice. The outline can be altered without prior notice to members. The company will not be liable for omissions or errors or the manner in which the outline is interpreted or used or for any outcomes emanating from the indirect or direct use of the outline. Members are advised to consult appropriate professionals or personal lawyers for professional and legal advice.
           </h4>
      </vs-col>
    </vs-row>
    <br />
    <br />
    <Footer></Footer>
    <!--  -->
    <!--  -->
  </div>
</template>
<script>
// import TheNavbarHorizontal from "../layouts/components/navbar/TheNavbarHorizontal.vue";
import Footer from "../../components/footer/Footer.vue";
export default {
  data: {},
  components: {
    // TheNavbarHorizontal,
    Footer,
    // HorizontalNavMenuHeader,
    // TheNavbarVerticalFullPage,
    // VerticalNavMenuGroup
  },
};
</script>

<style>
.back-grewond2 {
  background-image: url("../../assets/images/imgterm.png");
  width: 100%;
  background-repeat: round;
  background-attachment: local;
  background-size: cover;
}
/* p:hover {
    color: rgb(85, 255, 0);
  } */
/* .h1{
    color: #132953;
  } */
/* .desine{
        tex
    }, */
/* a:hover {
      color: rgb(34, 255, 0);
    } */
</style>
